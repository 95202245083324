<template>
  <v-container class="pa-0" fluid v-resize="onResize">

    <v-virtual-scroll :bench="2" :items="filteredParts" :height="virtualPartsScrollHeight"
      :item-height="isPhoneBool ? 52 : 64" style="background-color: white;">
      <template v-slot="{ item }">

        <v-list-item v-if="item.empty">
          <v-list-item-title>
            {{ $t("text.no_results_for") }} '...{{ search }}'
          </v-list-item-title>
        </v-list-item>

        <div v-else-if="item.loading">
          <v-list-item v-for="i in 10" :key="i" :style="'opacity: ' + (6 - i) / 5 + ';'">
            <v-list-item-avatar>
              <v-skeleton-loader type="button" width="60" height="60">
              </v-skeleton-loader>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-skeleton-loader type="text" width="100%">
                </v-skeleton-loader>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-skeleton-loader type="button" width="30" height="30">
              </v-skeleton-loader>
            </v-list-item-action>
            <v-list-item-action>
              <v-skeleton-loader type="button" width="30" height="30">
              </v-skeleton-loader>
            </v-list-item-action>
          </v-list-item>
        </div>

        <CommonPartListItem v-else :item="item" :machineId="machine.Id" :key="item.Id" @click="showItem(item)"
          @addToCart="addToCart(item)">
        </CommonPartListItem>

      </template>
    </v-virtual-scroll>
    <router-view></router-view>

  </v-container>
</template>


<script>
import http from "@/api/client";
import CommonPartListItem from "@/components/ListItems/CommonPartListItem.vue";

export default {
  name: "PartsPanel",
  components: {
    CommonPartListItem,
  },

  data() {
    return {
      virtualPartsScrollHeight: 500,
      search: "",
      parts: null,
      loading: false,
      machine: null,
    };
  },
  mounted() {
    this.init();
    if (this.machine) this.updateParts(this.machine);
    else this.$emit("updateMachine");
  },
  methods: {
    onResize() {
      console.log(window.innerHeight);
      this.virtualPartsScrollHeight = window.innerHeight - 204;
    },
    init() {
      if (this.$route.params.Machine) {
        this.machine = this.$route.params.Machine;
      }
    },

    updateParts(machine) {
      if (!machine) { return; }
      this.loading = true;
      http()
        .get("api2/customer/parts/" + machine.MachineModel.Id)
        .then((response) => {
          this.parts = response.data.map((s) => {
            s.Quantity = 1;
            s.CustomerArticlePrice = null;
            s.HasCustomerPrice = false;
            return s;
          });
          this.loading = false;
        });
      this.onResize();
    },

    showItem(item) {
      this.$router.push({
        name: "PartInfoDialog",
        params: { part: item.ArticleNumber },
      });
    },

    addToCart(item) {
      item.MachineId = this.machine.Id;
      this.$store.dispatch("addCart", item);
    },


    superSearch(value, list) {
      if (value === null || "" || value.length < 2) return [];
      var workingList = list.map((e) => e);
      //Attach points to each item
      workingList.forEach((e) => (e.points = 0));
      workingList.forEach((e) => {
        var namepart = (e.ArticleNumber + " " + e.ArticleDescription).split(
          " "
        );
        namepart.forEach((np) => {
          if (np.startsWith(value)) {
            e.points += 1;
          }
          //Divide the search input
          const swords = value.split(" ");
          swords.forEach((sword) => {
            if (sword != "") {
              // Points based on starting and
              // which number of word it is,
              // also, the length of the word
              if (np.startsWith(sword)) {
                e.points += 1; // * (swords.length - index) * e.Name.length;
              }
              // handle case invariance, halv the points
              else if (np.toLowerCase().startsWith(sword.toLowerCase())) {
                e.points += 0.5;
              }
              // Normalize, include points if exists, this way, we avoid loosing any
              else if (np.toLowerCase().includes(sword.toLowerCase())) {
                e.points += 0.1;
              }
            }
          });
        });
      });
      workingList.sort((a, b) => {
        return b.ArticleNumber < a.ArticleNumber;
      });
      workingList.sort((a, b) => {
        return b.points - a.points;
      });
      return workingList.slice(0, 10).filter((e) => e.points > 0);
    },
  },

  computed: {
    filteredParts() {
      if (this.loading) return [{ loading: true }];
      if (!this.parts) return [];
      if (this.search.length < 2) return this.parts;
      // do search
      const res = this.superSearch(this.search, this.parts);
      if (res.length == 0) {
        return [{ empty: true }];
      }
      return res;
    },
  },

  watch: {
    $route() {
      this.init();
    },
    machine(val) {
      this.updateParts(val);
    },
  },
};
</script>

<style scoped></style>