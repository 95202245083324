<template>
  <v-container fluid class="pa-0">
    <v-progress-linear v-if="shoppingCartLoading" absolute indeterminate></v-progress-linear>

    <v-row v-if="getTotalItems == 0" no-gutters>
      <v-col>
        <h4 class="mt-5 mb-4 mx-2">{{ $t("text.shopping_cart") }}</h4>
        <v-card-subtitle class="px-2">{{ $t("text.empty_shopping_cart") }}</v-card-subtitle>
      </v-col>
    </v-row>

    <v-row v-else no-gutters>
      <v-col v-if="mode != 'details'" :cols="!isPhoneBool ? 7 : 12">
        <h4 class="mt-5 mb-4 mx-2">{{ $t("text.shopping_cart") }}</h4>
        <CartItemList />
      </v-col>

      <v-divider v-if="mode != 'details'" vertical style="z-index: 2;"></v-divider>

      <v-col v-if="!isPhoneBool || mode == 'details'" :cols="!isPhoneBool ? 5 : 12" style="background-color: white;">
        <v-row v-if="mode == 'details'" align="center" no-gutters>
          <v-btn icon class="mx-2" @click="mode = null">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <h4 class="mt-4 mb-4 mx-2">{{ $t("text.finalize order") }}</h4>
        </v-row>
        <CartDetails />
      </v-col>
    </v-row>

    <div v-if="isPhoneBool && mode != 'details' && getTotalItems != 0"
      style="position: fixed; bottom: 56px; width: 100vw; z-index: 100; background-image: linear-gradient(transparent, white, white, white, white, white);"
      class="px-4 pt-2">
      <WideBtn :text="$t('text.next')" color="var(--v-primary-base)" @click="mode = 'details'">
      </WideBtn>
    </div>
  </v-container>
</template>


<script>
import CartItemList from "@/components/CartItemList.vue";
import CartDetails from "@/components/CartDetails.vue";
import WideBtn from "@/components/WideBtn.vue";

export default {
  name: "ShoppingCart",
  components: {
    CartItemList,
    CartDetails,
    WideBtn,
  },

  data() {
    return {
      mode: null,
    };
  },

  created() {
    this.$store.dispatch("getShoppingCart");
  },

  methods: {},

  computed: {
    shoppingCart() {
      if (this.$store.state.shoppingCart) {
        return this.$store.state.shoppingCart;
      }
      return [];
    },

    shoppingCartLoading() {
      return this.$store.state.shoppingCartLoading || false;
    },

    getTotalItems() {
      if (this.shoppingCart) {
        var c = 0;
        this.shoppingCart.forEach((v) => {
          c += v.length;
        });
        return c;
      }
      return 0;
    },
  },
};
</script>