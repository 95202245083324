<template>
  <PaginatedList :getListMethod="getJobs" :optional-filers="{}" :paginationEnabled="true" :itemsPerPage="50"
    :open-function="openJob" :pk="pk" :headerheight="0">
    <template v-slot:prepend>
      <v-col class="pa-0" style="flex-grow: 0;">
        <CostGraph :style="isPhoneBool ? '' : 'max-width: calc(100vw - 162px);'" />
        <v-toolbar flat class="mb-n2">
          <v-toolbar-title :class="isPhoneBool ? 'ml-n2' : ''">
            {{ $t("text.service_overview") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!--
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('text.search_list')" single-line outlined
          dense hide-details>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn icon @click="getJobs">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        -->
          <v-btn v-if="!isPhoneBool" color="success" class="ml-3" @click="newjobdialog = true">
            <v-icon>mdi-plus</v-icon>
            {{ $t("text.order_new_service") }}
          </v-btn>
        </v-toolbar>
        <v-list class="pb-0">
          <JobListItem :header="true" :key="'joblist-header'"> </JobListItem>
          <v-btn v-if="isPhoneBool" color="success" fab style="position: fixed; bottom: 70px; right: 12px"
            @click="newjobdialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list>
      </v-col>
    </template>

    <template v-slot:list-item="{ item }">
      <JobListItem :item="item" @click="openJob(item)" @machineClick="openJob(item)">
      </JobListItem>
    </template>

    <template v-slot:footer>
      <router-view></router-view>
      <NewJobDialog v-if="newjobdialog" @exit="jobdialogexit"> </NewJobDialog>
      <JobDialog></JobDialog>
      <v-snackbar v-model="toast">{{ toastMessage }}</v-snackbar>
      <Footer />
    </template>
  </PaginatedList>
</template>

<script>
import http from "@/api/client";
import CostGraph from "@/components/Cards/CostGraph.vue";
import JobDialog from "@/components/Dialogs/JobDialog";
import NewJobDialog from "@/components/Dialogs/NewJobDialog";
import Footer from "@/components/Footer.vue";
import JobListItem from "@/components/ListItems/JobListItem.vue";
import PaginatedList from "@/components/PaginatedList.vue";

export default {
  name: "JobList",
  components: {
    CostGraph,
    NewJobDialog,
    JobListItem,
    Footer,
    PaginatedList,
    JobDialog,
  },

  data() {
    return {
      toast: false,
      toastMessage: "",

      newjobdialog: false,
      loading: false,
      jobs: [],
      search: "",
      pk: "jobs",
    };
  },

  mounted() { },

  methods: {
    jobdialogexit(val) {
      if (val) {
        this.toastMessage = val;
        this.toast = true;
        // this.getJobs();
      }
      this.newjobdialog = false;
    },

    openJob(item) {
      this.$router.push({ name: this.$route.name, params: { jobId: item.Id } });
    },

    async getJobs(filters) {
      return await http()
        .get(
          "api2/customer/jobs/filtered" + this.serializeToQueryParams(filters)
        )
        .then((response) => {
          return response.data.Items;
        });
    },
  },

  computed: {},

  watch: {},
};
</script>

<style lang="css" scoped></style>
