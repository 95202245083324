<template>
  <v-card :flat="flat" :color="transparent ? 'transparent' : ''" :dark="dark">
    This portal is powered by <a href="www.qwips.no" style="text-decoration: none; color: inherit;"><b>Qwips Service Solutions.</b></a> <br>
    If you have any questions or further interest, please feel free contact us. <br><br>
    <v-icon size="18" class="mr-1">mdi-email</v-icon> Support@qwips.no <br>
    <v-icon size="18" class="mr-1">mdi-phone</v-icon> +47 22 22 44 00 <br>
    <v-icon size="18" class="mr-1">mdi-link-variant</v-icon><a href="www.qwips.no">www.qwips.no</a> <br>
  </v-card>
</template>


<script>
export default {
  name: "DeveloperContactCard",
  props: {
    dark: Boolean,
    transparent: Boolean,
    flat: Boolean,
  },
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>