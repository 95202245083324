<template>
    <!-- Folder view -->
    <div>
        <!-- Breadcrumbs -->
        <v-row v-if="crumbs.length" align="center" no-gutters class="px-4">
            <v-col class="crumb" cols="auto" style="cursor: pointer;" @click="crumbs = []">{{ $t('text.resources')
                }}</v-col>
            <v-col v-for="(crumb, index) in crumbs" :key="crumb.InheritedFromType" class="crumb"
                :class="(index < crumbs.length - 1) ? '' : 'last'" cols="auto">
                <v-row no-gutters>
                    <v-col cols="auto">
                        <v-icon size="14" class="mx-1">mdi-chevron-right</v-icon>
                    </v-col>
                    <v-col cols="0" @click="crumbs = crumbs.slice(0, index + 1)" style="cursor: pointer;">
                        <v-row no-gutters>
                            <v-col cols="auto">
                                <v-icon size="14" class="mr-1"
                                    v-text="(index < crumbs.length - 1) ? 'mdi-folder' : 'mdi-folder-open'">
                                </v-icon>
                            </v-col>
                            <v-col cols="0">
                                {{ folderName(crumb) }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- Sub-folders -->
        <template v-if="subFolders && countNestedFilesNotThisFolder(targetFolder)">
            <template v-if="!targetFolder.FlattenFolders">
                <FileSectionHeader v-model="targetFolder.foldersCollapsed" :text="$t('text.folders')" class="mt-1" />
                <div v-if="!targetFolder.foldersCollapsed" class="d-flex flex-wrap">
                    <template v-for="(subfolder, index) in subFolders">
                        <FolderThumb v-if="countNestedFiles(subfolder)" :key="index" :name="folderName(subfolder)"
                            :description="folderDescription(subfolder)" :fileCount="countNestedFiles(subfolder)"
                            :color="(subfolder.InheritedFromType == 'MachineJobHistory') ? '#7185E9' : '#4E9EEE'"
                            @click="crumbs.push(subfolder)">
                        </FolderThumb>
                    </template>
                </div>
            </template>
            <template v-else>
                <template v-for="(subfolder, index) in subFolders">
                    <div v-if="countNestedFiles(subfolder)" :key="index">
                        <FileSectionHeader v-model="subfolder.collapsed" :text="folderName(subfolder)"
                            :count="countNestedFiles(subfolder)" />
                        <div v-if="!subfolder.collapsed" class="d-flex flex-wrap mb-3">
                            <File v-for="(file, index) in  subfolder.Files" :key="folderName(subfolder) + '-' + index"
                                :file="file">
                            </File>
                        </div>
                    </div>
                </template>
            </template>
        </template>

        <!-- Files in this folder -->
        <template v-if="countNestedFiles(targetFolder)">
            <v-row no-gutters>
                <!-- Media section -->
                <template v-if="media">
                    <v-col :cols="wide ? 0 : 12" class="my-2">
                        <FileSectionHeader :value="targetFolder.mediaCollapsed" :text="$t('text.media')"
                            :count="media?.length" />
                        <div v-if="!targetFolder.mediaCollapsed" class="d-flex flex-wrap">
                            <File v-for="(file, index) in media" :key="'img-' + index" :file="file"></File>
                        </div>
                    </v-col>
                    <v-divider v-if="wide" vertical class="mt-3"></v-divider>
                </template>
                <!-- Form section -->
                <template v-if="forms">
                    <v-col :cols="wide ? 0 : 12" class="my-2">
                        <FileSectionHeader :value="targetFolder.formsCollapsed" :text="$t('text.forms')"
                            :count="forms?.length" />
                        <div v-if="!targetFolder.formsCollapsed" class="d-flex flex-wrap">
                            <File v-for="(file, index) in forms" :key="'forms-' + index" :file="file">
                            </File>
                        </div>
                    </v-col>
                    <v-divider v-if="wide" vertical class="mt-3"></v-divider>
                </template>
                <!-- Document section -->
                <template v-if="documents">
                    <v-col :cols="wide ? 0 : 12" class="my-2">
                        <FileSectionHeader :value="targetFolder.documentsCollapsed" :text="$t('text.documents')"
                            :count="documents?.length" />
                        <div v-if="!targetFolder.documentsCollapsed" class="d-flex flex-wrap">
                            <File v-for="(file, index) in documents" :key="'documents-' + index" :file="file">
                            </File>
                        </div>
                    </v-col>
                    <v-divider v-if="wide" vertical class="mt-3"></v-divider>
                </template>
                <!-- Part Drawing section -->
                <template v-if="partdrawings">
                    <v-col :cols="wide ? 0 : 12" class="my-2">
                        <FileSectionHeader :value="targetFolder.partdrawingsCollapsed" :text="$t('text.exploded_view')"
                            :count="partdrawings?.length" />
                        <div v-if="!targetFolder.partdrawingsCollapsed" class="d-flex flex-wrap">
                            <File v-for="(file, index) in partdrawings" :key="'partdrawings-' + index" :file="file">
                            </File>
                        </div>
                    </v-col>
                </template>
            </v-row>
        </template>
        <!-- Message when there are no files in folder -->
        <template v-else-if="!countNestedFiles(targetFolder) && crumbs && crumbs.length && totalSearchCount">
            <div class="d-flex flex-column justify-center align-center text-center flex-wrap px-3 pt-16 ">
                <v-icon size="80" class="mb-4">mdi-selection-search</v-icon>
                <small style="opacity: 0.7;">
                    {{ $t('text.no_files_in_this_folder') }}
                </small>
                <v-btn small text color="blue" class="px-1" @click="crumbs = []">{{ $t('text.jump_to_top_folder')
                    }}</v-btn>
            </div>
        </template>

    </div>
</template>


<script>
import FolderMixin from "@/helpers/mixins/FolderMixin.js";
import FileSectionHeader from "@/components/FileSectionHeader.vue";
import File from "@/components/File.vue";
import FolderThumb from "@/components/Cards/FolderThumb.vue";

export default {
    name: 'Folder',
    props: {
        folder: Object,
        totalSearchCount: Number,
        wide: Boolean,
    },
    components: {
        FileSectionHeader,
        File,
        FolderThumb,
    },
    mixins: [FolderMixin],

    data() {
        return {
            crumbs: [],
        };
    },

    methods: {},

    computed: {
        targetFolder() {
            var target = this.folder;
            const folderNameCheck = (a, b) => { return this.folderName(a) == this.folderName(b) }
            this.crumbs.forEach(crumb => {
                target = target.Folders.find(x => folderNameCheck(x, crumb));
            });
            return target;
        },

        subFolders() {
            var list = this.targetFolder.Folders;
            return list && list.length ? list : null;
        },

        media() {
            var list = this.filterFileArrByType(this.targetFolder.Files, 'Media')
            return list && list.length ? list : null;
        },
        documents() {
            var list = this.filterFileArrByType(this.targetFolder.Files, this.DocumentTypes.LINK)
            return list && list.length ? list : null;
        },
        forms() {
            var list = this.filterFileArrByType(this.targetFolder.Files, this.DocumentTypes.FORM)
            return list && list.length ? list : null;
        },
        partdrawings() {
            var list = this.filterFileArrByType(this.targetFolder.Files, this.DocumentTypes.PARTDRAWING)
            return list && list.length ? list : null;
        },
    },

    watch: {},
};
</script>


<style lang='scss'>
.crumb,
.crumb i {
    color: rgb(78, 78, 173) !important;
    font-size: 12px;
}

.crumb.last,
.crumb.last i {
    color: rgb(80, 80, 80) !important;
}
</style>