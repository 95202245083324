<template>
  <v-footer style="background-color: #f2f2f2 !important; min-height: 200px;">
    <v-container>
      <v-row>
        <v-col :cols="isPhoneBool ? 12 : 0">
          <CompanyContactCard flat transparent>
          </CompanyContactCard>
        </v-col>
        <v-divider :vertical="!isPhoneBool" class="mx-3"></v-divider>
        <v-col :cols="isPhoneBool ? 12 : 0">
          <ServiceProviderContactCard flat transparent>
          </ServiceProviderContactCard>
        </v-col>
        <v-divider :vertical="!isPhoneBool" class="mx-3"></v-divider>
        <v-col :cols="isPhoneBool ? 12 : 0">
          <DeveloperContactCard flat transparent>
          </DeveloperContactCard>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>


<script>
import CompanyContactCard from "@/components/Cards/CompanyContactCard.vue";
import ServiceProviderContactCard from "@/components/Cards/ServiceProviderContactCard.vue";
import DeveloperContactCard from "@/components/Cards/DeveloperContactCard.vue";


export default {
  name: "Footer",
  components: {
    CompanyContactCard,
    ServiceProviderContactCard,
    DeveloperContactCard,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang="scss"></style>