<template>
  <v-container fluid class="pa-0 parent_conatiner" ref="container" style="background-color: white;">
    <!--
    <ListToolbar v-model="itemList" :pk="pk" :objectName="'servicesystem.common_objects.machine.machines'">
      <template v-slot:toolbar-content-left>
        <template v-if="!$route.query[pk + 'filter']">
          <FilterTabs :pk="pk" :showFilterTab="showFilterTab" :showSortingTab="showSortingTab" />
        </template>
<FilterSearch v-if="$route.query[pk + 'filter']" :pk="pk" />
</template>
<template v-slot:toolbar-content-right>
        <RefreshBtn :method="() => { fetch() }" :externalLoading="loading"
          :tip="$t('servicesystem.common_objects.commonterms.refresh_list')" :size="'small'" class="ml-2">
        </RefreshBtn>
      </template>
</ListToolbar>
-->
    <slot name="header" />

    <QScroll :list="itemList" :loading="loading" :skeletonloading="!grid"
      :height="'calc(100vh - ' + headerheight + 'px)'" :minheight="'calc(100vh - ' + headerheight + 'px)'"
      :pagination="paginationEnabled ? pagination : null" :grid="grid" @loadNextPage="loadNextPage()">

      <template v-slot:prepend>
        <slot name="prepend" />
      </template>

      <template v-slot:list-item="{ item }">
        <slot name="list-item" :item="item">
          <v-list-item>
            <pre>{{ item }}</pre>
          </v-list-item>
        </slot>
      </template>

      <template v-slot:footer>
        <slot name="footer" />
      </template>

      <template v-slot:empty-filtered-list>
        <empty-page :icon="'mdi-filter-remove'"
          :title="$t('servicesystem.common_objects.fallbackterms.empty_filter', { okey: $t('servicesystem.common_objects.machine.machines').toLowerCase() })"
          :message="$t('servicesystem.common_objects.fallbackterms.empty_filter_body')" class="ignoreodd align-self-center">
        </empty-page>
      </template>

      <template v-slot:empty-list>
        <empty-page :icon="'mdi-playlist-remove'" :title="'Empty list'" :message="'The list is empty'"
          class="ignoreodd align-self-center">
        </empty-page>
      </template>
    </QScroll>
  </v-container>
</template>


<script>
import Vue from "vue";
//import clientAPI from "@/api/ClientAPIMixin.js";
//import FilterQueryMixin from "@/helpers/Mixins/FilterQueryMixin.js";
//import FilterTabs from "@/views/layouts/FilterTabs.vue";
//import FilterSearch from "@/views/layouts/FilterSearch.vue";
import EmptyPage from "@/views/EmptyPage.vue";
import QScroll from "@/components/QScroll.vue";
//import FilterSearch from "@/components/Inputs/FilterSearch.vue";
//import ListToolbar from "@/views/admin/machines/ListToolbar.vue";
//import RefreshBtn from "@/components/Buttons/RefreshDataBtn.vue";


export default {
  name: "ListPane",
  props: {
    pk: String,
    grid: Boolean,
    showFilterTab: Boolean,
    showSortingTab: Boolean,
    optionalFilers: Object,
    lockedFilters: Object,
    getListMethod: {
      type: Function,
      required: true
    },
    paginationEnabled: Boolean,
    itemsPerPage: Number,
    dialogTrigger: Number,
    dialogItem: Object,
    dialogMethodConfig: Object,
    setPreviewFunction: Function,
    openFunction: Function,
    headerheight: {
      type: [Number, String],
      default: 0,
    }
  },
  components: {
    QScroll,
    //FilterTabs,
    //FilterSearch,
    EmptyPage,
    //ListToolbar,
    //RefreshBtn,
  },
  // mixins: [clientAPI, FilterQueryMixin],

  data: () => ({
    itemList: null,
    loading: false,
    sucess: true,
    pagination: {
      page: 1,
      itemsPerPage: 100,
      nextPageLoading: false,
      partialPageReached: false,
    },
    mounted: false,
    focusedIndex: 0,
    jumpToIndex: 0,
    itemFocused: false,
    lastMove: 0,
  }),


  mounted() {
    console.log('mounted');
    if (this.paginationEnabled) {
      this.pagination.itemsPerPage = this.itemsPerPage;
    }
    this.fetch();
    this.mounted = true;
  },



  methods: {
    async fetch() {
      this.loading = true;
      this.itemList = null;
      Vue.set(this.pagination, "page", 1);
      var list = await this.getListFromFilter();
      console.log('fetch', list);
      this.itemList = list;
      this.multiSelectList = [];
      this.loading = false;
    },

    async getListFromFilter() {
      var filter = Object.assign({}, this.FiltersAndPagination);
      var list = await this.getListMethod(filter)
        .then((response) => {
          this.sucess = true;
          console.log('args', filter, "result", response);
          if (this.paginationEnabled) {
            if (response.length < this.pagination.itemsPerPage) {
              Vue.set(this.pagination, "partialPageReached", true);
            }
            else {
              Vue.set(this.pagination, "partialPageReached", false);
            }
          }
          else {
            Vue.set(this.pagination, "partialPageReached", true);
          }
          return response;
        })
        .catch((/*exception*/) => {
          this.sucess = false;
          return [];
        });
      return list;
    },

    async loadNextPage() {
      console.log('loadNextPage');
      this.pagination.page++;
      Vue.set(this.pagination, "nextPageLoading", true);
      var list = await this.getListFromFilter();
      list.forEach(o => {
        this.itemList.push(o)
      })
      Vue.set(this.pagination, "nextPageLoading", false);
    },
  },

  computed: {
    addedfilters() {
      if (this.optionalFilers || this.lockedFilters) {
        console.log('filters', this.optionalFilers, this.lockedFilter);
        var filters = Object.assign({}, this.optionalFilers);
        if (this.lockedFilters) {
          for (const key in this.lockedFilters) {
            filters[key] = this.lockedFilters[key]
          }
        }
        return filters;
      }
      else {
        return null;
      }
    },

    FiltersAndPagination() {
      var item = Object.assign({}, this.addedfilters);
      if (this.paginationEnabled) {
        item.ItemsPerPage = this.pagination.itemsPerPage;
        item.Page = this.pagination.page;
      }
      return item;
    },
  },

  watch: {
    "$route.query.timestamp"() {
      this.fetch();
    },

    addedfilters: function (newVal, oldVal) {
      if (!this.SameValue(newVal, oldVal)) {
        this.fetch();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.parent_conatiner {
  position: relative;
  display: flex;
  flex-flow: column;
  max-height: 100%;
}
</style>